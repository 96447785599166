.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  z-index: 99;
  .header-view {
    padding: 16px 20px;

    .navList {
      margin-left: 124px;
      grid-gap: 36px;
    }
  }
}

@media only screen and (max-width: 680px) {
  .header {
    .header-view {
    }
  }
}

.drawer-view {
  width: 100%;
  height: 100vh;
}
