@font-face {
  font-family: 'Kanit';
  font-weight: 400;
  font-style: normal;
  font-display: block;
  font-named-instance: 'Regular';
  src: url(./Kanit.woff) format('woff');
}

@font-face {
  font-family: 'Kanit-Medium';
  font-weight: 500;
  font-style: normal;
  font-display: block;
  font-named-instance: 'Regular';
  src: url(./Kanit-Medium.woff) format('woff');
}

@font-face {
  font-family: 'Kanit-Bold';
  font-weight: 700;
  font-style: normal;
  font-display: block;
  font-named-instance: 'Regular';
  src: url(./Kanit-Bold.woff) format('woff');
}